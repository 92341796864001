@use "../config/" as *;
.waf-fixtures-page {
  padding-block: 0 var(--space-8);
  .layout-wrapper {
    @extend %relative;
  }
  .waf-head {
    .title {
      @extend %neutral-50;
      @extend %font-20-pm;
    }
    .head-tab {
      @extend %mr-18;
      @extend %flex;
      li {
        border-radius: .2rem;
        @extend %neutral-50-bg;
      }
    }
    .head-wrap {
      flex-wrap: wrap;
    }
    .head-disclosure {
      @extend %neutral-50-5;
      @extend %font-12;
      @extend %mt-3;
      a {
        @extend %primary-500;
      }
    }
    .download-pdf {
      border: 0.05rem solid var(--neutral-50);
      width: 2rem;
      height: 2rem;
      @extend %mr-1;
      @extend %neutral-50-bg;
      a {
        @extend %font-0;
        @extend %transparent-bg;
      }
      &:before {
        content: "\e863";
        font-family: "waf-font-icon";
        @extend %absolute;
        @extend %font-12;
        @extend %secondary-1000;
      }
    }
    .calendar {
      @extend %p-1-2;
      a {
        @extend %p-0;
      }
      &::after {
        @include icon(calendar, 10);
        @extend %ml-1;
        @extend %secondary-1000;
      }
    }
  }
  .waf-body {
    @extend %flex-column;
    @extend %pt-4;
  }
  .filter {
    &-section {
      padding-right: var(--space-0);
      @include position(unset, 0, 100%);
      @extend %transparent-bg;
      @extend %absolute;
      .btn-more {
        top: 0;
        border-radius: 0.3rem;
        box-shadow: 0rem 0.4rem 1rem 0rem var(--neutral-800);
        @extend %my-0;
        @extend %gap-1;
        @extend %neutral-50;
        @extend %uppercase;
        @extend %secondary-1000-bg;
        @extend %font-12-pm;
      }
    }
  }
  .card {
    &-item {
      border: 0.1rem solid var(--secondary-1000);
      @extend %mb-3;
      @extend %full-radius;
      @extend %p-3;
      @extend %neutral-50-bg;
      & > * {
        @extend %secondary-1000;
      }
    }
    &-head {
      flex-direction: row-reverse;
      @extend %flex-n-fs;
      @extend %gap-2;
      @extend %pb-3;
      @extend %relative;
      &::after {
        content: "";
        height: 0.1rem;
        background: linear-gradient(to right, transparent, var(--neutral-100), transparent);
        opacity: 0.6;
        @extend %w-100;
        @extend %pos-bl;
      }
    }
    &-number {
      border-radius: 0.3rem;
      border: 0.08rem solid var(--secondary-1000);
      line-height: 1;
      @extend %px-2;
      @extend %py-1;
      @extend %secondary-1000-bg;
      @extend %neutral-50;
      @extend %uppercase;
      @extend %font-10-pm;
    }
    &-report {
      @extend %d-none;
    }
    &-content {
      @extend %my-3;
    }
    &-venue {
      @extend %pb-3;
      @extend %gap-2;
      @extend %flex-c-c;
      @extend %font-12-pr;
      @extend %relative;
      &::before {
        @include icon(venue, 16);
      }
      &::after {
        content: "";
        height: 0.1rem;
        background: linear-gradient(to right, transparent, var(--neutral-100), transparent);
        opacity: 0.6;
        @extend %w-100;
        @extend %pos-bl;
      }
    }
    &-footer-text {
      @extend %text-center;
      @extend %flex-c-c;
      @extend %my-3;
      @extend %font-10-pr;
    }
  }
  .team {
    @extend %flex-c-c;
    @extend %gap-2;
    &-wrapper {
      @extend %flex-sb-c;
    }
    &-image {
      width: 4rem;
      height: 4rem;
      flex-shrink: 0;
      @extend %flex-c-c;
    }
    &-image {
      border: 0.2rem solid var(--secondary-600);
      padding: 0.8rem;
      @extend %neutral-50-bg;
      @extend %w-100;
      @extend %h-100;
      @extend %circle-radius;
    }
    &-info {
      // flex-direction: row-reverse;
      flex-direction: column;
      @extend %flex-c-c;
      @extend %gap-2;
    }
    &-b {
      flex-direction: row-reverse;
      .team {
        &-info {
          // flex-direction: row;
          flex-direction: column;
        }
        &-extra {
          justify-content: flex-end;
        }
      }
    }
    &-name,
    &-score {
      @extend %font-14-pm;
    }
    &-extra {
      @extend %flex;
      @extend %mt-1;
    }
    &-data-wrap {
      @extend %flex-column;
      @extend %gap-2;
    }
  }
  .overs {
    opacity: 0.6;
    @extend %font-9-pr;
  }
  .date-time {
    @extend %mr-auto;
    @extend %flex-column;
    @extend %gap-2;
  }
  .date {
    @extend %font-12-pr;
  }
  .time {
    @extend %font-14-pm;
    &-text {
      border-radius: 0.3rem;
      line-height: 1;
      @extend %p-1;
      @extend %secondary-200-bg;
      @extend %font-9-pr;
      @extend %uppercase;
    }
  }
  .status {
    border-radius: 0.3rem;
    border: 0.08rem solid var(--secondary-1000);
    line-height: 1;
    @extend %p-1;
    @extend %uppercase;
    @extend %font-10-pm;
  }
  .full-name,
  .graph-box {
    @extend %d-none;
  }
  .btn-scorecard,
  .btn-ticket {
    border-radius: 0.3rem;
    @extend %font-11-pm;
    @extend %uppercase;
    @extend %py-2;
    @extend %primary-500-bg;
    @extend %w-100;
    @extend %flex-c-c;
  }
  .countdown {
    &-list {
      @extend %flex-c-c;
    }
    &-item {
      border-left: 0.06rem solid var(--secondary-200);
      @extend %flex-column;
      @extend %text-center;
      @extend %px-2;
      @extend %py-2;
      @extend %gap-1;
    }
    &-count {
      @extend %font-14-pm;
    }
    &-label {
      @extend %font-8-pr;
      @extend %uppercase;
    }
    &-wrapper {
      @extend %flex-column-n-c;
      @extend %gap-2;
      .icon {
        width: 1.5rem;
        height: 1.5rem;
        @extend %relative;
        &::before {
          content: "";
          @include icon(stopwatch, 15);
        }
      }
    }
    &-venue-wrapper {
      top: 0;
      width: max-content;
      @extend %pos-x-center;
    }
  }
  .live {
    &.card-item {
      border: 0.15rem solid var(--primary-500);
    }
    .status {
      border-color: var(--success-500);
      padding-left: var(--space-5);
      @extend %success-500;
      @extend %flex-n-c;
      @extend %relative;
      &::before {
        content: "";
        width: 1.5rem;
        height: 1.5rem;
        left: 0.8rem;
        pointer-events: none;
        animation: blinker 2s linear 0s infinite normal forwards;
        @include background(null, "svg/live.svg", center / contain no-repeat);
        @extend %absolute;
      }
    }
    .team {
      &:not(.team-current) {
        .team-name,
        .super-over {
          opacity: 0.6;
        }
        .team-image {
          border-color: var(--secondary-300);
        }
      }
      &-data {
        &:not(.super-over) {
          opacity: 0.6;
        }
      }
      &-current,
      &-won {
        .team-image {
          border-color: var(--primary-500);
        }
        .team-data {
          opacity: 0.6;
          &:last-child {
            opacity: 1;
          }
        }
      }
    }
  }
  .upcoming {
    &.card-item {
      @extend %secondary-100-bg;
    }
    .card {
      &-item {
        @extend %secondary-100-bg;
      }
      &-footer-text {
        // @extend %d-none;
      }
    }
    .team {
      &-detail {
        @extend %relative;
        @extend %py-3;
      }
    }
    .btn {
      &-scorecard {
        // @extend %d-none;
      }
      &-ticket {
        @extend %secondary-1000-bg;
        @extend %neutral-50;
        @extend %font-11-pr;
      }
    }
    .match-time {
      @extend %d-none;
    }
  }
  .recent {
    border-color: var(--error-500);
    .team {
      &-won {
        .team-data {
          opacity: 0.6;
          &:last-child {
            opacity: 1;
          }
        }
      }
      &:not(.team-won) {
        .team {
          &-name,
          &-data-wrap {
            opacity: 0.6;
          }
          &-image {
            border-color: var(--secondary-300);
          }
        }
      }
    }
    .card {
      &-report {
        left: 0;
        bottom: 7.3rem;
        @extend %flex-c-c;
        @extend %gap-2;
        @extend %px-3;
        @extend %w-100;
        @extend %absolute;
        .btn {
          &-group {
            @extend %flex-c-c;
            @extend %w-100;
            @extend %gap-2;
          }
          &-more {
            border: 0.05rem solid var(--secondary-700);
            flex: 1;
            @extend %py-2;
            @extend %flex-c-c;
            @extend %font-11-pm;
            @extend %uppercase;
            @extend %half-radius;
          }
          &-text {
            line-height: 1;
          }
        }
      }
      &-footer {
        .btn-group {
          padding-top: 4.4rem;
          @extend %w-100;
        }
      }
    }
    .status {
      border-color: var(--error-500);
      @extend %error-500;
    }
  }
  &.waf-shimmer {
    .team {
      &-image {
        border: 0;
        @include shimmer(4rem, 4rem);
      }
      &-logo {
        opacity: 0;
      }
      &-score {
        @include shimmer(5rem, 1.7rem, 0);
        .score {
          @extend %font-0;
        }
      }
      &-extra {
        @include shimmer(100%, 1rem, 0);
        .overs {
          opacity: 0;
        }
      }
    }
    .card-venue {
      @include shimmer(100%, 2rem, 0);
      &::before {
        @extend %d-none;
      }
    }
    .card-footer-text {
      @include shimmer(100%, 1.2rem, 0);
    }
    .time-text {
      @include shimmer(2rem, 2.2rem, 0);
    }
    .date {
      @include shimmer(12rem, 1.4rem, 0);
    }
    .time {
      @include shimmer(60%, 1.7rem, 0);
    }
    .card-number {
      border: 0;
      @include shimmer(24%, 2.2rem, 0);
      .text,
      .number {
        opacity: 0;
      }
    }
    .short-name {
      @include shimmer(2rem, 1.8rem, 0);
    }
    // .card-footer {
    .btn-group.btn {
      border: 0;
      @include shimmer(100%, 3.3rem, 0);
    }
    // }
    .status {
      border: 0;
      @include shimmer(18%, 2.1rem, 0);
      &::before {
        opacity: 0;
      }
    }
    .upcoming {
      .countdown {
        &-count {
          @include shimmer(100%, 1.5rem, 0);
        }
        &-label {
          opacity: 1;
          @include shimmer(2rem, 1.2rem, 0);
        }
        &-wrapper .icon {
          @include shimmer(1.5rem, 1.5rem, 0);
          &::before {
            opacity: 0;
          }
        }
      }
    }
  }
}
@media (min-width: $tablet-min-width) {
  .waf-fixtures-page {
    .waf-head {
      .title {
        font-size: 2.8rem;
      }
    }
    .filter-section {
      .btn-more {
        width: max-content;
      }
    }
    .card {
      &-item {
        padding-bottom: 8rem;
        padding-top: 1rem;
        position: relative;
        @include flex-config(flex, row, flex-start, center);
        &::after {
          content: "";
          width: 100%;
          height: 0.1rem;
          bottom: 6rem;
          background-color: var(--neutral-1000);
          opacity: 0.1;
          @include position-combo(x-center);
        }
      }
      &-head {
        width: 27%;
        flex-direction: row;
        flex-wrap: wrap;
        padding-bottom: var(--space-0);
        &::after {
          display: none;
        }
      }
      &-body {
        width: 100%;
        padding-top: var(--space-2);
        @include flex-config(flex, row, space-around, center);
      }
      &-content {
        width: 70%;
        padding-inline: var(--space-2);
        margin-block: var(--space-0);
      }
      &-footer {
        width: 30%;
      }
      &-footer-text {
        padding-top: var(--space-1);
        height: 2rem;
        justify-content: flex-start;
        text-align: left;
        font-size: 1.2rem;
        @include position(null, null, 1.5rem, 1.5rem);
      }
      &-venue {
        flex-direction: column;
        align-items: flex-start;
        padding-bottom: var(--space-0);
        &::before {
          font-size: 1.8rem;
        }
        &::after {
          display: none;
        }
      }
      &-number {
        font-size: 1rem;
        font-weight: 400;
      }
    }
    .btn {
      &-group {
        @include position(null, 1.5rem, 1.5rem);
      }
      &-more {
        width: 14rem;
      }
    }
    .status,
    .date-time {
      order: 1;
    }
    .team {
      &-name {
        font-size: 1.8rem;
      }
      &-score {
        font-size: 2rem;
        font-weight: 700;
      }
      &-a {
        justify-content: end;
      }
      &-info {
        flex-direction: row-reverse;
      }
      &-b {
        .team {
          &-info {
            flex-direction: row;
          }
        }
      }
    }
    .date-time {
      padding-top: var(--space-1);
    }
    .date {
      font-size: 1.4rem;
    }
    .time {
      font-size: 2rem;
      &-text {
        font-size: 1.2rem;
      }
    }
    .overs {
      font-size: 1.1rem;
    }
    .recent {
      .card {
        &-report {
          @include position(null, 5rem, 0rem);
          .btn {
            &-group {
              width: auto;
              right: 16.5rem;
            }
            &-more {
              flex: unset;
            }
          }
        }
        &-footer {
          .btn-group {
            padding-top: var(--space-0);
            width: auto;
          }
        }
        &-footer-text {
          width: 30%;
        }
      }
    }
    &.waf-shimmer {
      .team {
        &-image {
          border: 0;
          @include shimmer(5.8rem, 5.8rem);
        }
        &-score {
          @include shimmer(7rem, 2.3rem, 0);
        }
        &-extra {
          @include shimmer(100%, 1.2rem, 0);
          .overs {
            opacity: 0;
          }
        }
      }
      .date {
        @include shimmer(12rem, 1.8rem, 0);
      }
      .time {
        @include shimmer(60%, 2.4rem, 0);
      }
      .card-number {
        border: 0;
        @include shimmer(40%, 2.2rem, 0);
      }
      .short-name {
        display: none;
      }
      .full-name {
        @include shimmer(11rem, 4.6rem, 0);
      }
      .status {
        border: 0;
        @include shimmer(33%, 2.2rem, 0);
      }
      // .card-footer {
      .btn-group .btn {
        border: 0;
        @include shimmer(14rem, 3.3rem, 0);
      }
      // }
      .upcoming {
        .countdown {
          &-count {
            @include shimmer(2rem, 1.8rem, 0);
          }
          &-label {
            opacity: 1;
            @include shimmer(2rem, 1rem, 0);
          }
          &-wrapper .icon {
            @include shimmer(1.5rem, 1.5rem, 0);
            &::before {
              opacity: 0;
            }
          }
        }
      }
      .card-venue {
        @include shimmer(100%, 1rem, 0);
        &::before {
          display: none;
        }
      }
      .card-footer-text {
        @include shimmer(40%, 1.2rem, 0);
        position: absolute;
      }
      // .time-text {
      //   @include shimmer(2rem, 2.2rem, 0);
    }
  }
}
@media (min-width: $desktop-min-width) {
  .waf-fixtures-page {
    .card {
      &-head {
        width: 16%;
      }
      &-item {
        padding-inline: var(--space-4);
      }
      &-content {
        padding-inline: var(--space-2);
        width: 75%;
        position: relative;
        // border-left: 0.1rem solid var(--neutral-1000);
        .team {
          width: 45%;
          &-info {
            width: 75%;
            .team-name {
              width: 70%;
            }
          }
          &-data-wrap {
            width: 25%;
          }
          &-data,
          &-score,
          &-extra {
            width: 100%;
          }
        }
        .team-a {
          justify-content: end;
        }
        .team-b {
          justify-content: start;
        }
        .team-a {
          .full-name {
            justify-content: end;
          }
        }
        .team-b {
          .team-score {
            text-align: end;
          }
        }
        &::before,
        &::after {
          content: "";
          position: absolute;
          width: 0.1rem;
          height: calc(100% + 48%);
          background-color: var(--neutral-1000);
          opacity: 0.1;
          top: -27%;
        }
        &::before {
          left: 0;
        }
        &::after {
          right: 0;
        }
      }
      &-footer {
        width: 22%;
      }
    }
    .date-time {
      gap: var(--space-1);
    }
    .team {
      gap: var(--space-3);
      &-image {
        width: 5.8rem;
        height: 5.8rem;
      }
      &-logo {
        border-width: 0.2rem;
      }
      &-wrapper {
        gap: var(--space-1);
      }
      &-a {
        .full-name {
          text-align: right;
        }
      }
    }
    .countdown {
      &-wrapper {
        flex-direction: row;
      }
      &-item {
        &:last-child {
          border-right: 0.06rem solid var(--secondary-200);
        }
      }
      &-venue-wrapper {
        top: 20%;
      }
    }
    .short-name {
      display: none;
    }
    .full-name {
      display: flex;
      text-transform: capitalize;
    }
    .recent {
      .card {
        &-footer-text {
          width: 55%;
        }
      }
    }
    .upcoming {
      .team-wrapper {
        gap: calc(var(--space-20) * 2.5);
      }
      .card-content {
        .team-info {
          width: 100%;
        }
      }
    }
  }
}
@media (min-width: $xl-desktop-min-width) {
  .waf-fixtures-page {
    .upcoming {
      .team-wrapper {
        gap: calc(var(--space-20) * 2.9);
      }
    }
  }
}
@media screen and (max-width: 375px) {
  .waf-fixtures-page {
    .waf-head {
      .head-wrap {
        align-items: flex-start;
      }
      .head-tab {
        margin: var(--space-7) 0 0 0;
      }
    }
  }
}